import React, { ComponentType, FC } from "react";
import {
  LazyMotion,
  m,
  MotionProps,
  VariantLabels,
  domAnimation,
  Transition,
} from "framer-motion";

export interface BoxProps extends MotionProps {
  as?: ComponentType | keyof JSX.IntrinsicElements;
  transitionOverrides?: Transition;
  hiddenStyles?: object;
  visibleStyles?: object;
  children?: any;
  initial?: VariantLabels;
  animate?: VariantLabels;
  whileInView?: VariantLabels;
}

const Box: FC<BoxProps & React.HTMLAttributes<HTMLOrSVGElement>> = ({
  as = "div",
  children,
  transitionOverrides,
  hiddenStyles,
  visibleStyles,
  variants,
  animate = "visible",
  whileInView,
  initial = "hidden",
  viewport = {
    once: true,
  },
  ...rest
}) => {
  const boxVariants = {
    hidden: { opacity: 0, scale: 1, y: 10, ...hiddenStyles },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      ...visibleStyles,
      transition: {
        ease: [0.1, 0.25, 0.3, 1],
        duration: 1,
        delay: 0.15,
        ...transitionOverrides,
      },
    },
  };

  return (
    <LazyMotion features={domAnimation}>
      <m.div
        className="Box"
        viewport={{
          once: viewport.once,
          ...viewport,
        }}
        variants={variants || boxVariants}
        initial={initial}
        whileInView={animate || whileInView}
        {...rest}
      >
        {children}
      </m.div>
    </LazyMotion>
  );
};

export default Box;
